(function ibg() {
  let ibg = document.querySelectorAll('.ibg, .rbg , .bg');
  for (var i = 0; i < ibg.length; i++) {
    if (ibg[i].querySelector('img')) {
      let compStyle = getComputedStyle(ibg[i]);

      if (compStyle.backgroundImage) {
        let oldStyle = compStyle.backgroundImage;
        ibg[i].style.backgroundImage = oldStyle + ', url(' + ibg[i].querySelector('img').getAttribute('src') + ')';
      } else {
        ibg[i].style.backgroundImage = 'url(' + ibg[i].querySelector('img').getAttribute('src') + ')';
      }
    }
  }
})();
